























































import { Component, Prop } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import {
  ReporterAccessRequestModel,
  ReporterAccessRequestOrganisationModel,
  UserReporterOrganisationModel,
} from '@/core/webapi';
import { ReporterRequestStatus } from '@/core/constants';
import { ValidationMessageCpt } from '@/core/components';

@Component({
  components: {
    ValidationMessageCpt,
  },
})
export default class InputOrganisationCommentsAndActionsCpt extends AppVue {
  @Prop() model: ReporterAccessRequestModel;
  @Prop() organisation: UserReporterOrganisationModel | ReporterAccessRequestOrganisationModel;
  @Prop({ default: true }) showReporterPlaceholder: boolean;
  @Prop() orgIndex: number;

  reporterRequestStatus = ReporterRequestStatus;

  // This validation is a bit messy, but I REALLY didn't want to repeat some of the
  // logic, as things would probably get out of hand so it is what it is.

  get modelWithModelState() {
    return this.orgIndex == null || this.orgIndex == undefined ? this.organisation : this.model;
  }

  get reporterCommentValidationProp() {
    return this.orgIndex == null || this.orgIndex == undefined
      ? 'reporterComment'
      : `reporterOrganisations[${this.orgIndex}].ReporterComment`;
  }

  get reporterCommentValidationError() {
    return (this.modelWithModelState as any)?.modelState?.[this.reporterCommentValidationProp];
  }

  get mAdminCommentValidationProp() {
    return this.orgIndex == null || this.orgIndex == undefined
      ? 'medicalAdminComment'
      : `reporterOrganisations[${this.orgIndex}].MedicalAdminComment`;
  }

  get mAdminCommendValidationError() {
    return (this.modelWithModelState as any)?.modelState?.[this.mAdminCommentValidationProp];
  }

  async saveOrganisationRequestInternal(organisation: ReporterAccessRequestOrganisationModel) {
    this.$emit('saveOrganisationRequest', organisation);
  }

  async rejectOrganisationRequestInternal(organisationRequestId: number) {
    this.$emit('rejectOrganisationRequest', organisationRequestId);
  }
}
