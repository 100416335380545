































import { Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { ApiService } from '@/core/services';
import { InputFieldWithButtonCpt, SpinnerCpt } from '@/core/components';
import { ReporterAccessRequestInviteModel } from '@/core/webapi';

@Component({
  components: {
    InputFieldWithButtonCpt,
    SpinnerCpt,
  },
})
export default class ReporterInviteLinkReporterCpt extends AppVue {
  @Prop({ default: false }) showDialog: boolean;
  @Prop() organisationId: number;
  inviteKey = '';
  model = new ReporterAccessRequestInviteModel({
    organisationId: 0,
    email: '',
    modelState: {},
  } as any);

  get isOpen() {
    return this.showDialog;
  }

  get url() {
    return `${window.location.origin}/reporter-access-request/${this.inviteKey}`;
  }

  async created() {
    this.inviteKey = (await ApiService.organisations().getInviteKey(this.organisationId)).data;
    this.model.organisationId = this.organisationId;
  }

  close() {
    this.$emit('close');
  }

  copyInviteLink() {
    if (!this.isDevEnv) {
      navigator.clipboard.writeText(this.url).then(() => this.onLinkCopied());
    } else {
      // SEE: https://stackoverflow.com/a/71876238/413785
      this.unsecuredCopyToClipboard(this.url);
      this.onLinkCopied();
    }
  }

  onLinkCopied() {
    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('Copied to clipboard!') as string,
      type: 'success',
    });
  }

  unsecuredCopyToClipboard(text: string) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }

  async sendInvite() {
    await ApiService.reporterAccess().inviteReporter(this.model).handleValidationErrors(this.model);

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('Invite sent successfully') as string,
      type: 'success',
    });

    this.close();
  }
}
